import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './api/auth.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.getUser().pipe(map(data => {
      this.authService.user.next(data);
      let result = data && data.roles ? data.roles.includes('Admin') : false;
      !result && window.open(environment.frontUrl, '_self');
      return result;
    }),
      catchError(error => {
        window.open(environment.frontUrl, '_self');
        return of(false);
      })
    );
  }
}