import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, Validators } from '@angular/forms';
import { Photo } from '../../../models/photo.model';
import { FormSettings } from '../../../models/formSettings.model';
import { environment } from '../../../../environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { HelperService } from '../../../helpers/helper.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-edit-images',
  templateUrl: './edit-images.component.html',
  styleUrls: ['./edit-images.component.css']
})
export class EditImagesComponent implements OnInit {

  @Input('settings') fs: FormSettings = new FormSettings();
  @Input('parentForm') pForm: AbstractControl = new FormGroup({});
  control?: AbstractControl;
  photos: Photo[] = [];

  public uploader: FileUploader = new FileUploader({ url: environment.apiUrl + '/api/Photo/Upload', authToken: `Bearer ${this.cookieService.get('ProsocoToken')}`, itemAlias: 'photo' });

  constructor(private hs: HelperService, private cookieService: CookieService) { }

  ngOnInit(): void {
    this.control = this.pForm.get(this.fs.controlName) || undefined;
    this.photos = [...this.fs.value] || [];
    if (!this.photos.length) this.photos.push(new Photo());
    this.photos.forEach(p => p.originalUrl = p.originalUrl || 'assets/img/logo-Placeholder.png');
  }

  handleDrop(event: File[]) {
    this.hs.uploadQueue(this.uploader, (data) => {
      this.photos = this.photos.filter(p => p.id);
      this.photos.push(data);
      this.control?.markAsTouched();
      this.control?.setValue(this.photos);
    })
  }

  handleDeletePhoto(photo: Photo) {
    this.photos = this.photos.filter(p => p.id !== photo.id);
    this.control?.setValue(this.photos);
  }

  handleSelect(event: Event) {
    let fileList = (event.target as HTMLInputElement).files
    fileList && this.handleDrop(fileList as unknown as File[]);
  }

}
